import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const Photos = ({ videoSrcURL, videoTitle, ...props }) => (
  <Section id="home photos">
    <MediaWrapper>
      <Container>
          <h1>Home Photos</h1>
          <br/>
          <br/>
          <br/>
          <h3>Pine Crest Homes</h3>
          
        <Grid>
        
        <img src="https://whisperingoakscb.com/housephotos/01-20Lilac.jpg" alt="01" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/02-20Lilac.jpg" alt="02" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/03-20Lilac.jpg" alt="03" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/04-20Lilac.jpg" alt="04" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/05-20Lilac.jpg" alt="05" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/06-20Lilac.jpg" alt="06" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/07-20Lilac.jpg" alt="07" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/08-20Lilac.jpg" alt="08" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/09-20Lilac.jpg" alt="09" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/10-20Lilac.jpg" alt="10" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/11-20Lilac.jpg" alt="11" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/12-20Lilac.jpg" alt="12" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/13-20Lilac.jpg" alt="13" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/14-20Lilac.jpg" alt="14" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/15-20Lilac.jpg" alt="15" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/16-20Lilac.jpg" alt="16" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/17-20Lilac.jpg" alt="17" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/18-20Lilac.jpg" alt="18" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/19-20Lilac.jpg" alt="19" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/20-20Lilac.jpg" alt="20" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/21-20Lilac.jpg" alt="21" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/22-20Lilac.jpg" alt="22" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/23-20Lilac.jpg" alt="23" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/24-20Lilac.jpg" alt="24" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/25-20Lilac.jpg" alt="25" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/26-20Lilac.jpg" alt="26" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/27-20Lilac.jpg" alt="27" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/28-20Lilac.jpg" alt="28" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/29-20Lilac.jpg" alt="29" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/30-20Lilac.jpg" alt="30" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/31-20Lilac.jpg" alt="31" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/32-20Lilac.jpg" alt="32" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/33-20Lilac.jpg" alt="33" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/34-20Lilac.jpg" alt="34" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/35-20Lilac.jpg" alt="35" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/36-20Lilac.jpg" alt="36" style={{maxWidth:560,width:'auto'}}/>

        
        </Grid>
        <br/>
          <br/>
          <br/>
          <h3>Richland Homes</h3>

        <Grid>
        
        <img src="https://whisperingoakscb.com/housephotos/01-7Alder.jpg" alt="01" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/02-7Alder.jpg" alt="02" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/03-7Alder.jpg" alt="03" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/04-7Alder.jpg" alt="04" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/05-7Alder.jpg" alt="05" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/06-7Alder.jpg" alt="06" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/07-7Alder.jpg" alt="07" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/08-7Alder.jpg" alt="08" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/09-7Alder.jpg" alt="09" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/10-7Alder.jpg" alt="10" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/11-7Alder.jpg" alt="11" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/12-7Alder.jpg" alt="12" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/13-7Alder.jpg" alt="13" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/14-7Alder.jpg" alt="14" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/15-7Alder.jpg" alt="15" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/16-7Alder.jpg" alt="16" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/17-7Alder.jpg" alt="17" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/18-7Alder.jpg" alt="18" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/19-7Alder.jpg" alt="19" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/20-7Alder.jpg" alt="20" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/21-7Alder.jpg" alt="21" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/22-7Alder.jpg" alt="22" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/23-7Alder.jpg" alt="23" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/24-7Alder.jpg" alt="24" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/25-7Alder.jpg" alt="25" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/26-7Alder.jpg" alt="26" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/27-7Alder.jpg" alt="27" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/28-7Alder.jpg" alt="28" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/29-7Alder.jpg" alt="29" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/30-7Alder.jpg" alt="30" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/31-7Alder.jpg" alt="31" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/32-7Alder.jpg" alt="32" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/33-7Alder.jpg" alt="33" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/34-7Alder.jpg" alt="34" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/35-7Alder.jpg" alt="35" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/36-7Alder.jpg" alt="36" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/37-7Alder.jpg" alt="37" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/38-7Alder.jpg" alt="38" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/39-7Alder.jpg" alt="39" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/40-7Alder.jpg" alt="40" style={{maxWidth:560,width:'auto'}}/>

        
        </Grid>
        
        
        <br/>
          <br/>
          <br/>
          <h3>Aerial Photos</h3>

        <Grid>
        
        <img src="https://whisperingoakscb.com/housephotos/01-WhisperingOaks_001.jpg" alt="01" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/02-WhisperingOaks_002.jpg" alt="02" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/03-WhisperingOaks_003.jpg" alt="03" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/04-WhisperingOaks_004.jpg" alt="04" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/05-WhisperingOaks_005.jpg" alt="05" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/06-WhisperingOaks_006.jpg" alt="06" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/07-WhisperingOaks_007.jpg" alt="07" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/08-WhisperingOaks_008.jpg" alt="08" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/09-WhisperingOaks_009.jpg" alt="09" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/10-WhisperingOaks_010.jpg" alt="10" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/11-WhisperingOaks_011.jpg" alt="11" style={{maxWidth:560,width:'auto'}}/>
        <img src="https://whisperingoakscb.com/housephotos/12-WhisperingOaks_012.jpg" alt="12" style={{maxWidth:560,width:'auto'}}/>

        
        </Grid>
             
        
      </Container> 
    </MediaWrapper>
  </Section>
)



const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  padding: 10px 0px 96px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
    padding: 32px 0px;
  }
`;



const MediaWrapper = styled.div`
  padding-top: 96px;
  padding-bottom: 128px;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 0px;
  }
`; 

export default Photos;
