import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ExternalLink from '@common/ExternalLink';
import { Section, Container } from '@components/global';


const BUILDERS = [
  {
    name: 'Woodland Homes',
    image: 'woodlandhomes.jpg',
    price: '$400,000+*',
    desc: 'Woodland Homes has been building in the Omaha area since 1979. It started out as the villa arm of Birchwood Homes Inc. and since has grown to include numberous single family floor plans such as the Cabernet and ever popular Louden plan. Ask questions and explore the journey of creating your dream home!',
    logo: 'woodlandlogo.svg',
    link: 'https://buildwoodland.com/',
  },
  {
    name: 'Pine Crest Homes',
    image: 'pinecresthomes.jpg',
    price: '$325,000 - $400,000*',
    desc: "Our spacious and functional open floor plans fit you and your family's lifestyle. Our homebuilding process is stress free. Working with our team of experts, you will choose from Pine Crest's ranch style and two story floor plans. You'll then hand-select finishes and additions from our portfolio of options that will make the home yours.",
    logo: 'pinecrestlogo.svg',
    link: 'https://buildpinecrest.com/',
  },
  {
    name: 'Richland Homes',
    image: 'richlandhomes.jpg',
    price: '$275,000 - $325,000*',
    desc: "Express yourself in choices and options that allow you to personalize your home. We have popular 2 story and ranch plans! Richland Homes offer plenty of choices to make sure your home is the dream you've always wanted.",
    logo: 'richlandlogo.svg',
    link: 'https://buildrichland.net/',
  },
];

const Builders = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "builders" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="builders" accent="secondary">
        <BuilderWrapper>
          <Container style={{ position: 'relative' }}>
              <h1>Build Your Dream Home</h1>
              <h3>BHI Development</h3>
              <TeamGrid>
                  {BUILDERS.map(({ name, logo, image, price, desc, link }) => {
                    const img = data.allFile.edges.find(
                      ({ node }) => node.relativePath === image
                    ).node;
                    return (
                      <ExternalLink key={link} href={link}>
                        <div key={name}>
                          <img src={logo} alt={name} />
                          <Img fluid={img.childImageSharp.fluid} alt={name} />
                          <Name>{name}</Name>
                          <Price>{price}</Price>
                          <Desc>{desc}</Desc>
                        </div>
                      </ExternalLink>
                    );
                  })}

              </TeamGrid>
          </Container>
        </BuilderWrapper>
      </Section>
    )}
  />
);

const BuilderWrapper = styled.div`
  background-color: ${props => props.theme.color.primary};
  padding: 128px 0;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

const Name = styled.p`
  margin-top: 16px;
  ${props => props.theme.font_size.larger};
  color: ${props => props.theme.color.black.regular};
`;

const Price = styled.p`
  margin-top: 16px;
  ${props => props.theme.font_size.large};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
`;

const Desc = styled.p`
  margin-top: 16px; 
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

export default Builders;
