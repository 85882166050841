import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_map1: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "map1" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_phase1: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "phase1" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_phase2: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "phase2" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Banner>
            <div>
              <br />
              <a href="#contact"><h3>NOW TAKING LOT RESERVATIONS FOR THE NEXT PHASE!</h3></a>
            </div>
          </Banner>
          <Grid>
            <div>
              <h2>Perfect Location</h2>
              <p>
              Whispering Oaks is the newest premier subdivision in Council Bluffs, Iowa. It is located just east of Council Bluffs at the corner of Greenview Road and Franklin Avenue. Whispering Oaks offers more than 50 acres of mature woodlands with wildlife, walking trails and biking trails throughout. It provides a community lifestyle with easy access to shopping, nightlife, schools and the interstate system.
              </p>
            </div>
            <Art>
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDnl5kpkmk2YVBPKLBbt5W2T6jsN6Q9IsU&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_phase1.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Neighborhood Amenities</h2>
              <p>
                <ul>
                  <li>Choose from 150 Lots (Phase #1 & #2)</li>
                  <li>Coming Soon: 100 lots (Phase #3)</li>
                  <li>Serene Wooded Lots</li>
                  <li>Easy Interstate Access</li>
                  <li>Lewis Central Schools</li>
                  <li>Shopping & Entertainment near by </li>
                  <li>Located At Greenview Road & Franklin Ave</li>
                </ul>
              </p>   
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>A Great Community</h2>
              <p>
              Over 400 homeowners will enjoy this new, suburban neighborhood setting when completed. First and Second phase of Whispering Oaks offers 150 prime lots with custom and semi-custom home and villa plans from Richland Homes, Pine Crest Homes, Pine Crest Villas, and Woodland Homes as your builders.   
              The Third phase will add an additional 100 lots. Whispering Oaks is just minutes to anywhere in the Omaha metro area. It’s a quick drive to Metro Crossing Shopping Center, Iowa School for the Deaf, Lake Manawa, Mid-America Center, Council Bluffs Recreation Complex and more.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_phase2.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;
  padding: 64px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;



const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const Banner = styled.div`
  text-align: center;
`;

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 41.245349, lng: -95.802125 }}
  >
    <Marker
      position={{ lat: 41.245349, lng: -95.802125 }}
    />
  </GoogleMap>
));

export default About;
