import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const Media = ({ videoSrcURL, videoTitle, ...props }) => (
  <Section id="virtual tours">
    <MediaWrapper>
      <Container>
          <h1>Virtual Tours</h1>
        <Grid>
        <iframe src="https://my.matterport.com/show/?m=hMqGyouuxGb" frameborder="0" width="560" height="315" allowfullscreen allow="xr-spatial-tracking"></iframe>
        <iframe src="https://player.vimeo.com/video/469156845" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>  
        <iframe src="https://my.matterport.com/show/?m=KyDAVLDz6Pt" frameborder="0" width="560" height="315" allowfullscreen allow="xr-spatial-tracking"></iframe>  
        <iframe src="https://player.vimeo.com/video/467613760" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>   
        <iframe src="https://my.matterport.com/show/?m=tFkmPDCVyMa" frameborder="0" width="560" height="315" allowfullscreen allow="xr-spatial-tracking"></iframe> 
        <iframe src="https://player.vimeo.com/video/468404501" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> 
        <iframe src="https://my.matterport.com/show/?m=FtnXKP3NGGS" frameborder="0" width="560" height="315" allowfullscreen allow="xr-spatial-tracking"></iframe>        
        <iframe src="https://my.matterport.com/show/?m=niUkzR52J58" frameborder="0" width="560" height="315" allowfullscreen allow="xr-spatial-tracking"></iframe>       
               
         
        
        
        
        </Grid>
        <Grid>
          <div className="video">
            <iframe src="https://player.vimeo.com/video/467418003" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
          <div className="video">
            <iframe title="Whispering Oaks Drone Video 1" width="560" height="315" src="https://www.youtube.com/embed/4AKYR7SF2SU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>      
          <div className="video>">
            <iframe title="Whispering Oaks Drone Video 2" width="560" height="315" src="https://www.youtube.com/embed/8AjaiD9y6Rc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className="video">
            <iframe title="Whispering Oaks Drone Video 3" width="560" height="315" src="https://www.youtube.com/embed/-WLyMWIEsgg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Grid>
        <Grid>
          <div className="video">
            <video width="560" height="315" controls>
                <source src="https://whisperingoakscb.com/videos/Whispering_oaks_vFINAL.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div>
        </Grid>
        
             
        
      </Container>
    </MediaWrapper>
  </Section>
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;
  padding: 96px 0px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
    padding: 32px 0px;
  }
`;

const MediaWrapper = styled.div`
  padding-top: 96px;
  padding-bottom: 128px;

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 0px;
  }
`;

export default Media;
